import React, { useState } from "react";
import styles from "./PresaleStratIn.module.css";
import ProgressBar from "../../../Home/FindProject/SingleProject/ProgressBar/ProgressBar";
import ClosedIn from "./ClosedIn/ClosedIn";

const PresaleStratIn = ({ amount, setAmount, idodata, buyButton, hardcap, totalRaised, minPurchase }) => {
  // console.log(totalRaised, hardcap, "caps")
  let percentage = (totalRaised / hardcap) * 100;


  let startTime = idodata?.startTime;
  let endTime = idodata?.endTime;
  let status;
  let start = startTime * 1000;
  let end = endTime * 1000;
  let now = new Date().getTime();

  if (now > end) {
    status = "finished";
  }
  if (now > start && now < end) {
    status = "live";
  }

  if (now < start) {
    status = "upcoming";
  }
  console.log(status, "status")
  console.log(end, "end")

  let time = status === "upcoming" ? start : end;
  let timeString = new Date(time).toString();
  console.log(timeString, "timeString")
  return (
    <div className={[styles.wrapper, styles.presaleStartIn].join(" ")}>
      <h4 className={styles.presaleStart}>{status == "finished" ? "Ido is Over" : status == "live" ? "Ido ends in" : "Ido starts in"}</h4>
      <ClosedIn time={timeString} />
      <div className={styles.progressContainer}>
        <p className={styles.progressText}>Progress ({percentage}%)</p>
        <ProgressBar progress={percentage} />
        <p className={[styles.price, styles.progressText].join(" ")}>
          <span>{totalRaised} usdt</span>
          <span>{hardcap} usdt</span>
        </p>
      </div>{" "}
      <div className={styles.amountContainer}>
        <p className={styles.amountText}>Amount</p>
        <div className={styles.inputContainer}>
          <input
            type="text"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            className={styles.input}
          />
          <p className={styles.max}>MAX</p>
        </div>
      </div>
      <button
        onClick={() => buyButton.func()}
        className={styles.connectButton}>{buyButton.text}</button>
    </div>
  );
};

export default PresaleStratIn;
