import React from "react";
import { heroBg } from "../../../images/image";
import styles from "./HeroSection.module.css";

const HeroSection = () => {
  return (
    <section className={`wrapper ${styles.hero}`}>
      {" "}
      <div className={styles.bg}>
        <img src={heroBg} className={styles.img} alt="#" />
      </div>
      <div className={`contain ${styles.contain}`}>
        <div className={styles.content}>
          {/* <h3>
            Explore the Possibilities <br /> of Top IDOs with{" "}
            <span>Crypto Investors AI</span>{" "}
          </h3> */}
          <h3>
            Use Crypto Investors AI to find<br /> and invest in promising new<br /> blockchain projects.


          </h3>

          <p>
            Crypto Investors AI is a blockchain launchpad that provides businesses with a suite of services to simplify the process of launching their projects on multiple blockchain networks.


          </p>
          <button className="source">Get Started</button>
        </div>
      </div>
    </section>
  );
};

export default HeroSection;
