import React, { useState } from "react";

import styles from "./LockToken.module.css";

const LockToken = () => {
  const [values, setValues] = useState({
    contractAddress: "",
    amount: "",
    date: "",
  });

  const handleSubmit = () => {};

  const inputs = [
    {
      label: "Contract Address",
      placeholder: "Enter your Contract Address",
      type: "text",
      name: "contractAddress",
    },
    {
      label: "Amount",
      placeholder: "Enter the amount",
      type: "number",
      name: "amount",
    },

    { label: "Date", type: "date", name: "date" },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };

  const handleKeyDown = (event) => {
    if (event.key !== "Backspace" && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <form action="" className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.wrapper}>
        {inputs.map((input, i) => (
          <div className={styles.inputContainer} key={i}>
            <label htmlFor={input.name} className={styles.label}>
              {input.label}
            </label>
            <input
              type={input.type}
              value={values[input.name]}
              placeholder={input.placeholder}
              name={input.name}
              onChange={onChange}
              id={input.name}
              className={styles.input}
              onKeyDown={input.type === "number" ? handleKeyDown : null}
            />
          </div>
        ))}{" "}
        <button
          onClick={handleSubmit}
          type="submit"
          className={styles.connectButton}
        >
          Submit
        </button>
      </div>
    </form>
  );
};

export default LockToken;
