import React, { useState } from "react";
import Switch from "./Switch/Switch";
import styles from "./CreateIdo.module.css";
import { useAccount } from "wagmi";
import { ethers } from "ethers";
import { useSigner } from "wagmi";
import { erc20ABI } from "wagmi";
import { toast } from "react-toastify";
import axios from "axios";


let abi = [
  {
    "inputs": [],
    "stateMutability": "nonpayable",
    "type": "constructor"
  },
  {
    "anonymous": false,
    "inputs": [
      {
        "indexed": true,
        "internalType": "address",
        "name": "token",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "ido",
        "type": "address"
      },
      {
        "indexed": true,
        "internalType": "address",
        "name": "owner",
        "type": "address"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "rate",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "publicCap",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "whitelistCap",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "minPurchase",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "whitelistLength",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "softcap",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "hardcap",
        "type": "uint256"
      },
      {
        "indexed": false,
        "internalType": "uint256",
        "name": "liquidityPercent",
        "type": "uint256"
      }
    ],
    "name": "IdoDeployed",
    "type": "event"
  },
  {
    "inputs": [
      {
        "internalType": "address",
        "name": "_token",
        "type": "address"
      },
      {
        "internalType": "uint256",
        "name": "_rate",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_publicCap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_whitelistCap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_minPurchase",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_whitelistLength",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_softcap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_hardcap",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_startTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_whitelistEndTime",
        "type": "uint256"
      },
      {
        "internalType": "uint256",
        "name": "_endTime",
        "type": "uint256"
      }
    ],
    "name": "deployIdo",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "deploymentFee",
    "outputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [
      {
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
      }
    ],
    "name": "idos",
    "outputs": [
      {
        "internalType": "contract Ido",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  },
  {
    "inputs": [],
    "name": "owner",
    "outputs": [
      {
        "internalType": "address",
        "name": "",
        "type": "address"
      }
    ],
    "stateMutability": "view",
    "type": "function"
  }
];


const CreateIdo = () => {

  const onLogoChange = async (e) => {
    if (!ethers.utils.isAddress(values.tokenContract)) {
      toast.error("Invalid Token Address");
      setValues({ ...values, tokenimage: "" });
      return;
    }
    try {
      toast.info("Updating Image");
      let api = `https://api.uns.technology/updateImage/${values.tokenContract}`;
      //update image
      let image = e.target.files[0];
      let formData = new FormData();
      formData.append("image", image);
      let { data } = await axios.post(api, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
      );

      if (data.message == "success") {
        toast.success("Image Updated");
        setValues({ ...values, tokenimage: data.image });
      }

    } catch (e) {
      console.log(e)
    }
  };
  let { address } = useAccount();
  const { data: signer } = useSigner();
  const [values, setValues] = useState({
    tokenContract: "",
    tokenname: "",
    tokensymbol: "",
    tokendecimals: "",
    tokensupply: "",
    tokenprice: "",
    softcap: "",
    publiccap: "",
    whitelistcap: "",
    hardcap: "",
    startdate: "",
    enddate: "",
    whitelisted: "",
    whitelistendtime: "",
    tokenimage: "",
    description: "",
    banner: "",
    telegramurl: "",
    twitterurl: "",
    githuburl: "",
    websiteurl: "",
    whitelistlength: "",
  });

  const setTokenInfo = async (e) => {
    let token = values.tokenContract;
    if (ethers.utils.isAddress(token) === false) {
      setValues({
        ...values,
        tokenname: "",
        tokensymbol: "",
        tokendecimals: "",
        tokensupply: "",
      });
      return;
    };
    if (token === "") return;
    let tokenContract = new ethers.Contract(
      token,
      erc20ABI,
      signer
    );
    let name = await tokenContract.name();
    let symbol = await tokenContract.symbol();
    let decimals = await tokenContract.decimals();
    let supply = await tokenContract.totalSupply();

    setValues({
      ...values,
      tokenname: name,
      tokensymbol: symbol,
      tokendecimals: decimals,
      tokensupply: Number(ethers.utils.formatUnits(supply, decimals)).toFixed(2),
    });
  };

  React.useEffect(() => {
    setTokenInfo();
  }, [values.tokenContract]);
  let factoryAddress = "0x9d1c81E2C7E1672c83eFAD178d3236cC8428213c";
  let factory = new ethers.Contract(
    factoryAddress,
    abi,
    signer
  );


  // React.useEffect(() => {
  //   console.log(values.tokenimage)
  // }, [values]);
  const deployIdo = async () => {
    // console.log(values.tokenprice);
    // console.log(values.publiccap);
    // console.log(values.whitelistcap);
    // console.log(values.whitelistlength);
    // console.log(values.softcap);
    // console.log(values.hardcap);
    // console.log(Date.parse(values.startdate) / 1000);
    // console.log(Date.parse(values.whitelistendtime) / 1000);
    // console.log(Date.parse(values.enddate) / 1000);

    try {
      toast.loading("Updating MetaData", {
        position: "top-center",
        theme: "colored",
        toastId: "Updating",
      });

      let api = `https://api.uns.technology/updateMetaData/${values.tokenContract}`;

      let { data } = await axios.post(api, {
        description: values.description,
        telegramurl: values.telegramurl,
        twitterurl: values.twitterurl,
        githuburl: values.githuburl,

      });
      if (data.message == "success") {
        toast.success("MetaData Updated");
        toast.dismiss("Updating");
      } else {
        toast.error("MetaData Update Failed");
        return;
      }

      toast.loading("Deploying Ido Contract", {
        position: "top-center",
        theme: "colored",
        toastId: "deploying",
      });
      // let tx = await factory.deployIdo(
      //   values.tokenContract,
      //   values.tokenprice,
      //   ethers.utils.parseEther(values.publiccap),
      //   ethers.utils.parseEther(values.whitelistcap),
      //   "1", //minpurchase
      //   values.whitelistlength,
      //   ethers.utils.parseEther(values.softcap),
      //   ethers.utils.parseEther(values.hardcap),

      //   //convert to unix
      //   // values.startdate,
      //   Date.parse(values.startdate) / 1000,
      //   Date.parse(values.whitelistendtime) / 1000,
      //   Date.parse(values.enddate) / 1000
      // );
      // let reciept = await tx.wait();
      // console.log(reciept);
      // toast.update("deploying", {
      //   render: "IDO Deployed",
      //   type: "success",
      //   isLoading: false,
      //   autoClose: 2000,
      // });

      // toast.loading("Updating Metadata : Dont close the page", {
      //   position: "top-center",
      //   theme: "colored",
      //   toastId: "updating",
      // });







    } catch (error) {
      // console.log(error);
    }
  };



  const [whiteListed, setWhiteListed] = useState(false);

  const inputs = [

    {
      label: "Token Contract",
      placeholder: "Enter your Token Contract",
      type: "text",
      name: "tokenContract",
    },
    {
      label: "Token Name",
      placeholder: "Enter your Token Name",
      type: "text",
      name: "tokenname",
    },
    {
      label: "Token Symbol",
      placeholder: "Enter your Token Symbol",
      type: "text",
      name: "tokensymbol",
    },
    {
      label: "Token Supply",
      placeholder: "Enter your Supply",
      type: "number",
      name: "tokensupply",
    },
    {
      label: "Token Decimals",
      placeholder: "Enter your Token Decimals",
      type: "number",
      name: "tokendecimals",
    },
    {
      label: "Token Price",
      placeholder: "Enter your Token Price",
      type: "number",
      name: "tokenprice",
    },
    {
      label: "Public Cap",
      placeholder: "Enter your Token Decimals",
      type: "number",
      name: "publiccap",
    },
    {
      label: "Whitelist Cap",
      placeholder: "Enter your Token Decimals",
      type: "number",
      name: "whitelistcap",
    },
    {
      label: "SoftCap",
      placeholder: "Enter Sofcap Number",
      type: "number",
      name: "softcap",
    },
    {
      label: "HardCap",
      placeholder: "Enter HardCap Number",
      type: "number",
      name: "hardcap",
    },
    {
      label: "Whitelist Length",
      placeholder: "Enter HardCap Number",
      type: "number",
      name: "whitelistlength",
    },
    { label: "Start Date", type: "date", name: "startdate" },
    { label: "End Date", type: "date", name: "enddate" },
  ];
  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    deployIdo();
  };
  const handleKeyDown = (event) => {
    if (event.key !== "Backspace" && !/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  return (
    <form action="" className={styles.form} onSubmit={handleSubmit}>
      <div className={styles.wrapper}>
        <div>
          {inputs.map((input, i) => (
            <div className={styles.inputContainer} key={i}>
              <label htmlFor={input.name} className={styles.label}>
                {input.label}
              </label>
              <input
                type={input.type}
                value={values[input.name]}
                placeholder={input.placeholder}
                name={input.name}
                onChange={onChange}
                id={input.name}
                className={styles.input}
                onKeyDown={input.type === "number" ? handleKeyDown : null}
              />
            </div>
          ))}{" "}
          <div className={styles.inputContainer}>
            <div className={styles.labelContainer}>
              <label htmlFor="whitelistendtime" className={styles.label}>
                Whitelist Endtime
              </label>
              <Switch
                isOn={whiteListed}
                handleToggle={() => {
                  setValues({ ...values, whitelisted: whiteListed });
                  setWhiteListed((prev) => !prev);
                }}
                colorTwo="#003ecb"
                colorOne="#30303E"
              />{" "}
            </div>

            <input
              type="date"
              value={values["whitelistendtime"]}
              name="whitelistendtime"
              onChange={onChange}
              id="whitelistendtime"
              className={[
                styles.input,
                !whiteListed && styles.inputDisabled,
              ].join(" ")}
            />
          </div>
        </div>
        <div>




          {" "}
          <>
            <div className={styles.inputContainer}>
              {" "}
              <label className={styles.label}>Token Image</label>
              <div className={styles.inputWrapper}>
                <input
                  type="file"
                  value={values["tokenimage"]}
                  name="tokenimage"
                  id="tokenimage"
                  onChange={(e) => {
                    onChange(e);
                    onLogoChange(e);
                  }}
                  className={styles.input}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              <textarea
                placeholder="Description"
                name="description"
                id="description"
                value={values["description"]}
                onChange={onChange}
                className={[styles.input, styles.textArea].join(" ")}
              ></textarea>
            </div>{" "}
            <div className={styles.inputContainer}>
              {" "}
              <label className={styles.label}>Banner</label>
              <div className={styles.inputWrapper}>
                <input
                  type="file"
                  value={values["banner"]}
                  name="banner"
                  id="banner"
                  onChange={onChange}
                  className={styles.input}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              {" "}
              <label className={styles.label}>Telegram</label>
              <div className={styles.inputWrapper}>
                <input
                  type="url"
                  value={values["telegramurl"]}
                  name="telegramurl"
                  id="telegramurl"
                  placeholder="Enter Telegram Url"
                  onChange={onChange}
                  className={styles.input}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              {" "}
              <label className={styles.label}>Twitter</label>
              <div className={styles.inputWrapper}>
                <input
                  type="url"
                  value={values["twitterurl"]}
                  name="twitterurl"
                  id="twitterurl"
                  placeholder="Enter Twitter Url"
                  onChange={onChange}
                  className={styles.input}
                />
              </div>
            </div>
            <div className={styles.inputContainer}>
              {" "}
              <label className={styles.label}>Website</label>
              <div className={styles.inputWrapper}>
                <input
                  type="url"
                  value={values["githuburl"]}
                  name="githuburl"
                  id="githuburl"
                  placeholder="Enter Web Url"
                  onChange={onChange}
                  className={styles.input}
                />
              </div>

            </div>
          </>


        </div>
      </div>
      <button
        onClick={handleSubmit}
        type="submit" className={styles.connectButton}>
        Submit
      </button>
    </form>
  );
};

export default CreateIdo;
