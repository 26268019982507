import React, { useState } from "react";

import { icon1, project, successFull, upcoming } from "../../../images/image";

import { TbWorld } from "react-icons/tb";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { HiOutlineShieldCheck } from "react-icons/hi";
import styles from "./Details.module.css";
import { ethers } from "ethers";

const Details = ({
  feature = ["Kyc+", "audit"],
  image = project,
  // icon = `https://api.uns.technology/logo/${idodata?.token}.png`,
  title = "About Lyopay",
  info = "fetching data...",
  web = "#",
  twitter = "#",
  linkedin = "#",
  idodata,
  startTime,
  endTime,
  metadata
}) => {
  const [showAll, setShowAll] = useState(false);
  function toggleShowAll() {
    setShowAll(!showAll);
  }
  info = metadata?.description ? metadata?.description : info;
  twitter = metadata?.twitterurl ? metadata?.twitterurl : twitter;
  let telegram = metadata?.telegramurl ? metadata?.telegramurl : twitter;
  web = metadata?.web ? metadata?.web : web;
  const data = [
    {
      key: "Presale Address",
      value: idodata?.ido,
    },
    {
      key: "Token Address",
      value: idodata?.token,
    },
    {
      key: "Token Name",
      value: idodata?.tokenName,
    },
    {
      key: "Token Symbol",
      value: idodata?.tokenSymbol,
    },
    {
      key: "Total Supply",
      value: idodata?.totalSupply ? ethers.utils.formatEther(idodata?.totalSupply) : 0,
    },

    {
      key: "Token For Presale",
      value: 80000000,
    },
    {
      key: "Token For Liquidity",
      value: 25000000,
    },
    {
      key: "Liquidity Percent",
      value: idodata?.liquidityPercent,
    },
    {
      key: "Softcap",
      value: idodata?.softcap ? ethers.utils.formatEther(idodata?.softcap) : 0,
    },
    {
      key: "Hardcap",
      value: idodata?.hardcap ? ethers.utils.formatEther(idodata?.hardcap) : 0,
    },
    {
      key: "rate",
      value: idodata?.rate ? (idodata?.rate) : 0,
    },
    {
      key: "Listing On",
      value: "Uniswap",
      gradient: true,
    },

  ];


  const isLive = () => {
    let sT = idodata?.startTime * 1000;
    let eT = idodata?.endTime * 1000;
    let timemili = Date.parse(new Date());


    if (timemili < sT) {
      return "Upcoming";
    }
    if (timemili > sT && timemili < eT) {
      return "Live";
    }
    if (timemili > eT) {
      return "Finished";
    }
  }

  let isLiveStatus = isLive();

  React.useEffect(() => {

  }, [idodata, isLiveStatus]);
  return (
    <section>
      {" "}
      <div className={styles.card}>
        <img src={image} alt="#" className={styles.banner} />

        <div className={styles.projectInfo}>
          <div className={styles.header}>
            <img src={`https://api.uns.technology/logo/${idodata?.token}.png`} alt="#" className={styles.icon} />
            <div className={styles.featuresAndStatus}>
              {" "}
              <div className={styles.features}>
                {feature.map((el, i) => (
                  <p className={styles.feature} key={i}>
                    <HiOutlineShieldCheck className={styles.checkmark} />
                    <span> {el}</span>
                  </p>
                ))}
              </div>
              {isLiveStatus == "live" && (
                <p className={[styles.status, styles.live].join(" ")}>
                  <span className={styles.circle}></span>

                  <span className={styles.statusText}>Sale is Live</span>
                </p>
              )}
              {isLiveStatus == "Upcoming" && (
                <p className={[styles.status, styles.succesFull].join(" ")}>
                  <img src={successFull} alt="#" className={styles.statusImg} />

                  <span className={styles.statusText}>Upcoming</span>
                </p>
              )}{" "}
              {isLiveStatus == "Finished" && (
                <p className={[styles.status, styles.upcoming].join(" ")}>
                  <img src={upcoming} alt="#" className={styles.statusImg} />

                  <span className={styles.statusText}>Finished</span>
                </p>
              )}
            </div>
          </div>

          <div className={styles.heading}>
            <p className={styles.title}>{idodata?.tokenName}</p>
            <div className={styles.socialContaner}>
              <a
                href={web}
                target="_blank"
                rel="noreferrer"
                className={styles.iconContainer}
              >
                <TbWorld className={styles.socialIcon} />
              </a>
              <a
                href={"https://twitter.com/" + twitter}
                target="_blank"
                rel="noreferrer"
                className={styles.iconContainer}
              >
                <FaTwitter className={styles.socialIcon} />
              </a>{" "}

            </div>
          </div>

          <p className={styles.info}>
            {showAll ? info : info.slice(0, 99)}
            <span
              className={[styles.info, styles.showMore].join(" ")}
              onClick={toggleShowAll}
            >
              {info.length > 99 &&
                (showAll ? " ... See Less" : " ... See More")}
            </span>
          </p>
          <div className={styles.details}>
            <h6 className={styles.detailsTitle}>Details</h6>
            {data.map((el, i) => (
              <div className={styles.keyAndValue} key={i}>
                <p className={styles.key}>{el.key}</p>
                <p
                  className={[
                    styles.value,
                    el.gradient && styles.gradient,
                  ].join(" ")}
                >
                  {el.value}
                </p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Details;
