import { useState } from "react";
import { MdClose } from "react-icons/md";
import { AiOutlineAlignRight } from "react-icons/ai";
import { BiChevronUp, BiChevronDown } from "react-icons/bi";
import { Link, NavLink } from "react-router-dom";
import { ConnectButton } from "@rainbow-me/rainbowkit";

import styles from "./Header.module.css";
import {
  avatar,
  eth,
  binance,
  arbiturm,
  optimism,
  logo,
  newLogo,
} from "../../images/image";

const Header = () => {
  const [connected, setConnected] = useState(false);
  const [headerToggle, setHeaderToggle] = useState(false);
  const [currency, setCurrency] = useState({ icon: eth, name: "Ethereum" });
  const allCurrency = [
    { icon: eth, name: "Ethereum" },
    { icon: binance, name: "Binance Smart Chain" },
    { icon: arbiturm, name: "Arbitrum One" },
    { icon: optimism, name: "Optimism" },
  ];
  const [dropDown, setDropDown] = useState(false);
  const navItems = [
    { navItem: "Home", to: "/" },
    { navItem: "Create Token", to: "/minter" },
    // { navItem: "Create IDO", to: "/createIDO" },
    // { navItem: "Lock Token", to: "/lockToken" },
    { navItem: "Docs", to: "/" },
  ];

  return (
    <header className={`wrapper ${styles.header}`}>
      <div className={`${styles.header__inner}`}>
        <Link to="/">
          {" "}
          <img
            // width="200"
            // src={"https://solidhash.io/logo.svg"}
            src={newLogo}
            alt=""
            className={styles.logo}
          />
        </Link>
        <nav style={{ right: headerToggle ? "0" : "-100%" }}>
          {navItems.map((el, i) => (
            <NavLink
              to={el.to}
              className="source"
              key={i}
              onClick={() => setHeaderToggle((prev) => !prev)}
            >
              {el.navItem}
            </NavLink>
          ))}
          <div className={` ${styles.header__btns}`}>
            <ConnectButton />
          </div>
        </nav>

        <button
          onClick={() => setHeaderToggle((prev) => !prev)}
          className={`${styles.mobile} ${styles.toggleHeader}`}
        >
          {headerToggle ? (
            <MdClose className={styles.hamberGer} />
          ) : (
            <AiOutlineAlignRight className={styles.hamberGer} />
          )}
        </button>
      </div>
    </header>
  );
};

export default Header;
