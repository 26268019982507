import React from "react";
import { TbWorld } from "react-icons/tb";
import { FaTwitter, FaLinkedinIn } from "react-icons/fa";
import { HiOutlineShieldCheck } from "react-icons/hi";
import ProgressBar from "./ProgressBar/ProgressBar";
import styles from "./SingleProject.module.css";
import { successFull, upcoming } from "../../../../images/image";

import ClosedIn from "./ClosedIn/ClosedIn";
import { Link } from "react-router-dom";

const Card = ({
  status,
  feature,
  image,
  icon,
  wlSpot,
  title,
  info,
  web,
  twitter,
  linkedin,
  softcap,
  hardcap,
  progress,
  liquidity,
  startPrice,
  endPrice,
  ca,
  closedIn,
  startTime,
  endTime,
  whitelistEndTime
}) => {

  let start = startTime * 1000;
  let end = endTime * 1000;
  let whitelistEnd = whitelistEndTime * 1000;
  let now = new Date().getTime();

  if (now > end) {
    status = "finished";
  }
  if (now > start && now < end) {
    status = "live";
  }

  if (now < start) {
    status = "upcoming";
  }
  return (
    <div className={styles.card} onClick={
      () => {
        window.location.href = `/ido/${ca}`
      }
    }>
      <img src={image} alt="#" className={styles.banner} />

      <div className={styles.projectInfo}>
        <div className={styles.header}>
          <img src={icon} alt="#" className={styles.icon} />
          <div className={styles.featuresAndStatus}>
            {" "}
            <div className={styles.features}>
              {feature.map((el, i) => (
                <p className={styles.feature} key={i}>
                  <HiOutlineShieldCheck className={styles.checkmark} />
                  <span> {el}</span>
                </p>
              ))}
            </div>
            {(
              <p className={[styles.status, styles.live].join(" ")}>
                <span className={styles.circle}></span>

                <span className={styles.statusText}>{status}</span>
              </p>
            )}

          </div>
        </div>

        <div className={styles.heading}>
          <p className={styles.title}>{title}</p>
          {/* <div className={styles.socialContaner}>
            <a
              href={web}
              target="_blank"
              rel="noreferrer"
              className={styles.iconContainer}
            >
              <TbWorld className={styles.socialIcon} />
            </a>
            <a
              href={twitter}
              target="_blank"
              rel="noreferrer"
              className={styles.iconContainer}
            >
              <FaTwitter className={styles.socialIcon} />
            </a>{" "}
            <a
              href={linkedin}
              target="_blank"
              rel="noreferrer"
              className={styles.iconContainer}
            >
              <FaLinkedinIn className={styles.socialIcon} />
            </a>
          </div> */}
        </div>
        <p className={styles.info}>{info}</p>
        <div className={styles.softCaps}>
          <p className={styles.softCap}>
            <span className={styles.text}>Softcap :</span>{" "}
            <span className={`${styles.softCapValue} ${styles.text}`}>
              {softcap}
            </span>{" "}
          </p>{" "}
          <p className={styles.softCap}>
            <span className={styles.text}>Hardcap :</span>{" "}
            <span className={`${styles.softCapValue} ${styles.text}`}>
              {hardcap}
            </span>{" "}
          </p>{" "}
          <p className={styles.softCap}>
            <span className={styles.text}>Liquidity :</span>
            {"  "}
            <span className={`${styles.softCapValue} ${styles.text}`}>
              {liquidity}%
            </span>{" "}
          </p>{" "}
          <p className={styles.softCap}>
            <span className={styles.text}>WL :</span>{" "}
            <span className={`${styles.softCapValue} ${styles.text}`}>
              {wlSpot} Spots
            </span>{" "}
          </p>{" "}
        </div>
        <div className={styles.progressContainer}>
          <p className={styles.progressText}>Progress ({progress}%)</p>
          <ProgressBar progress={progress} />
          <p className={[styles.price, styles.progressText].join(" ")}>
            <span>{startPrice} usdt</span>
            <span>{endPrice} usdt</span>
          </p>
        </div>
        <div className={styles.bottom}>
          <div className={styles.closedIn}>
            <ClosedIn status={status} time={status == "upcoming" ? new Date(startTime * 1000).toString() : status == "live" ? new Date(endTime * 1000).toString() : "0"} />
          </div>
          <Link to={"/ido/" + ca} className={styles.seeDetails}>
            See Details
          </Link>
        </div>
      </div>
    </div >
  );
};

export default Card;
